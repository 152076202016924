@import '~assets/stylesheets/variables.scss';

.container {
  color: $gray-1;
  background-color: $gray-2;
  border-radius: 50%;
  height: 1.4rem;
  width: 1.4rem;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

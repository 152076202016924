.container {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.navigator-container {
  flex: 0 18%;
  max-width: 240px;
}

.wizard-content-container {
  flex: 1 65%;
  display: flex;
  &.vertical-steps {
    flex: 1 1 100%;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

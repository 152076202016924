@import '~assets/stylesheets/variables.scss';

.wizard-page-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  direction: rtl;
  .right-panel,
  .left-panel {
    display: flex;
    align-items: center;
    padding: 0 4rem;
  }
  .hidden {
    visibility: hidden;
  }
}

.container {
  direction: rtl;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.login-required-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.signin-with-google-button {
  margin-top: 2rem;
  background-color: $gray-1 !important;
  border: 1px solid $gray-3;
  color: $gray-3 !important;

  .button-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .button-label {
      margin-left: 1.5rem;
    }
  }
}

//-------------------------------
.table-header {
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid black;
  .table-header-label {
    font-size: 2rem;
    &:first-of-type {
      border-left: 1px solid black;
    }
  }
}

.table-header-label {
  background-color: $very-dark-green;
  color: $gray-1;
  padding: 1rem 1rem;
  overflow: hidden;
}

.table-container {
  display: flex;
  font-size: 1.8rem;
  border-radius: 10px;
  margin-top: 10rem;
  box-shadow: 0px 0 10px rgb(170, 170, 170);
}

.table-column {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &:first-of-type {
    // border: 1px solid black;
    border-top-right-radius: 10px;
  }
  &:nth-of-type(2) {
    border-top-left-radius: 10px;
  }
}

.table-data {
  margin: 2rem 2rem 2rem 6rem;
  // padding: 0 3rem;
  &.price::before {
    content: '₪';
    font-size: 1.6rem;
  }
}

.table-data-highlight {
  @extend .table-data;
  color: $very-dark-green;
  font-weight: bold;
}

.container {
  padding: 1rem;
  direction: rtl;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  align-items: center;
}

.title {
  font-size: 2.7rem;
  margin-bottom: 1.2rem;
  margin-top: 1.5rem;
  font-weight: bold;
}

.faqs-container {
  height: 70vh;
  overflow: scroll;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
}

.faq-container {
  width: 80rem;
  margin: 1.3rem 0;
  margin: 0 1rem;
}

.question {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.answer {
  font-size: 1.8rem;
}

.container {
  padding: 5rem 18rem;
  font-size: 1.7rem;
  direction: rtl;
  height: 100%;
}

.title {
  font-size: 2.2rem;
  font-weight: bold;
  margin: 1rem 1rem 2rem 0;
}

.section-title {
  font-weight: bold;
  margin: 1rem;
}

.content {
  height: 70%;
  overflow: scroll;
}

@import '~assets/stylesheets/variables.scss';

.content-container {
  background: rgb(221, 252, 223);
  background: linear-gradient(
    180deg,
    rgba(221, 252, 223, 0.44348161627932425) 0%,
    rgba(221, 252, 223, 0.44348161627932425) 0%
  );
  height: 500px;
  padding-top: 100px;
  padding-right: 180px;
  //   font-weight: bold;
}

.content {
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  align-items: flex-end;
}

.description {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 15px;
}

.sub-description {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 35px;
  direction: rtl;
  width: 700px;
}

.start-wizard-button {
  padding: 10px 40px;
  color: white;
  background-color: $dark-green;
  font-size: 30px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
}

.start-wizard-link {
  text-decoration: none;
}

.footer {
  padding: 2rem 18rem;
  direction: rtl;
  display: flex;
  // flex-direction: column;
  font-size: 1.4rem;
  .link {
    color: black;
    margin-left: 2rem;
  }
}

@import '~assets/stylesheets/variables.scss';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10rem;
  direction: rtl;
}

.title {
  font-size: 4rem;
  color: $dark-green;
  margin-bottom: 1rem;
}

.label {
  font-size: 2.5rem;
}

.mail {
  color: $gray-3;
  font-size: 2.5rem;
}

@import '~assets/stylesheets/variables.scss';

.container {
  display: flex;
  min-width: 24rem;
}

.nav-option {
  margin: 0 30px;
  font-size: 19px;
  font-weight: 600;
  color: black;
}

.selected-nav-option {
  color: $dark-green;
}

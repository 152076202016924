@import '~assets/stylesheets/variables.scss';

.login-required-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.signin-with-google-button {
  margin-top: 2rem;
  background-color: $gray-1 !important;
  border: 1px solid $gray-3;
  color: $gray-3 !important;

  .button-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .button-label {
      margin-left: 1.5rem;
    }
  }
}

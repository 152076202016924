@import '~assets/stylesheets/variables.scss';

.field-textarea {
  width: 70rem;
  height: 40rem;
  border-radius: 5px;
  border: 1px solid $gray-1;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.8rem;
  color: $gray-3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.027);
}

.header-container {
  display: flex;
  padding: 22px 40px;
  flex-direction: row-reverse;
  background-color: white;
}

.nav-pane-container {
  margin-right: 200px;
  display: flex;
  align-items: center;
}

.wizard-steps {
  margin-right: 4rem;
  margin-left: auto;
}

@import '~assets/stylesheets/variables.scss';

.container {
  display: flex;
  align-items: center;
}

.pay-with-bit-button {
  margin-top: 2rem;
  margin-right: 6rem;
  background-color: $gray-1 !important;
  border: 1px solid $gray-3;
  color: $gray-3 !important;

  .button-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .button-label {
      margin-left: 1.5rem;
    }
  }
  .logo {
    width: 4rem;
  }
}

.label {
  font-size: 2.5rem;
  font-weight: bold;
}

.modal-container {
  direction: rtl;

  .modal-title {
    font-size: 2.5rem;
  }

  .modal-description {
    margin-top: 2rem;
    font-size: 2rem;
  }

  .modal-important {
    color: rgb(153, 38, 38);
    font-size: 2rem;
    font-weight: bold;
    margin-top: 1.5rem;
  }

  .modal-button {
    padding: 1rem 1.5rem;
    font-size: 2rem;
    border-radius: 10px;
  }

  .footer {
    display: flex;
    margin-top: 4rem;
    justify-content: space-between;
  }
}

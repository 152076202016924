@import '~assets/stylesheets/variables.scss';

.logo-container {
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  align-items: center;
  height: 100%;
  .su-title {
    color: black;
  }

  .book-title {
    color: $dark-green;
  }
}

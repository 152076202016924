//a9d153, 31aa5d
$light-green: #aed36c;
$dark-green: #69ab3d;
$very-dark-green: #3c7a15;
$light-gray: #d9d9d9;
$dark-gray: #77818b;

$gray-0: #f4f5f72d;
$gray-1: #eef0f2;
$gray-2: #9ca8b5;
$gray-3: #667686;

$blue: #151d97;

@import '~assets/stylesheets/variables.scss';

.wizard-container {
  width: 100%;
  min-width: 24rem;
  height: 100%;
  border-left: 1px solid #d9d9d9;

  &.vertical-steps {
    display: flex;
    flex-direction: row-reverse;
    border-left: none;
    align-items: center;

    .progress-bar {
      width: 6rem;
      height: 0.5rem;
      border-radius: 5px;
      background-color: $gray-1;
      display: flex;
      flex-direction: row-reverse;

      &.completed {
        background-color: $dark-green;
      }
    }

    .completed-progress-bar {
      background-color: $dark-green;
      height: 100%;
      animation: progressBar 0.5s ease-in-out;
      animation-fill-mode: both;
      border-radius: 5px;
    }

    @keyframes progressBar {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }

  &.reverse-steps {
    flex-direction: row;
  }
}

@import '~assets/stylesheets/variables.scss';

.publisher-container {
  display: inline-block;
  margin-top: 2rem;
  position: relative;
}

.publisher {
  // box-shadow: 0px 0px 30px rgb(0 0 0 / 11%);
  cursor: pointer;
  margin: 2.5rem;
  border-radius: 20px;
  padding-bottom: 1rem;
  width: 30rem;
  display: flex;
  background-color: white;

  align-items: center;
  flex-direction: column;
}

.publisher:hover {
  background-color: #e1e3e686;
  transition: background-color 0.3s;
}

.publisher-label {
  font-size: 2.2rem;
  font-weight: 600;
}

.site {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.additional-details {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.additional-details-titles {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: stretch;
  .small-description {
    font-size: 1.4rem;
    color: $gray-2;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: flex-start;
    flex: 4;
    display: flex;
    &:nth-of-type(2):not(:last-child) {
      flex: 5;
    }
    padding: 0 0.2rem;
    &:not(:first-of-type) {
      border-right: 1px solid $gray-1;
    }
  }
  .medium-description {
    @extend .small-description;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
}

.pricing-details {
  width: 100%;
}

.publisher-details {
  font-size: 2.5rem;
  font-weight: bold;
  flex-wrap: wrap;
  text-align: center;

  padding: 0 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 4;
  align-self: stretch;
  &:nth-of-type(2) {
    flex: 5;
  }
  &.time-to-treat {
    font-size: 2.8rem;
  }
  &.books-type {
    font-size: 1.8rem;
  }
  &.communication-model {
    font-size: 1.8rem;
  }
  &.pricing {
    font-size: 3.2rem;
    margin-top: auto;
    color: $very-dark-green;
    display: flex;
    flex-direction: column;
    flex: 4;
    .price {
      &::before {
        content: '₪';
        font-size: 1.6rem;
      }
    }
  }
  .description {
    font-size: 1.5rem;
    color: $gray-2;
    margin-bottom: auto;
  }

  &:not(:first-of-type) {
    border-right: 1px solid $gray-1;
    // border-image: linear-gradient(
    //     to bottom,
    //     rgba(2, 0, 36, 0) 0%,
    //     $gray-1,
    //     rgba(0, 0, 0, 0)
    //   )
    //   1 100%;
  }
}

.select-publisher {
  background-color: $very-dark-green;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 11%);
  color: white;
  .pricing {
    color: white;
  }
}

.select-publisher:hover {
  background-color: $very-dark-green;
}

.logo {
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  top: -2rem;
  width: 10rem;
  height: 10rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  box-shadow: 0px 15px 20px #0000001c;
}

.details {
  display: flex;
  flex-direction: column;
  margin-top: 7rem;
  align-items: center;
  width: 90%;
}

.type-label {
  padding: 0.2rem 0.8rem;
  font-size: 1.4rem;
  border-radius: 10px;
  margin: 1rem;
  font-weight: bold;
  margin: 2rem;
  &.TRADITIONAL {
    color: white;
    background-color: $gray-2;
  }
  &.MODERN {
    color: white;
    background-color: $blue;
  }
}

@import '~assets/stylesheets/variables.scss';

.container {
  display: flex;
  font-size: 2.6rem;
  font-weight: bold;
  color: $very-dark-green;
  & > div {
    margin: 4rem 2rem;
  }
}

.price {
  &::before {
    content: '₪';
    font-size: 1.6rem;
  }
}

@import '~assets/stylesheets/variables.scss';

.button {
  padding: 2rem 3.5rem;
  font-size: 2.3rem;
  border-radius: 25px;
  display: inline-flex;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.5s;
  // z-index: 4;
}

.button.submit {
  background-color: $very-dark-green;
  color: white;
}

.submit:hover {
  background-color: $very-dark-green;
}

.button.button-disabled {
  background-color: #d9d9d9;
  pointer-events: none;
  opacity: 0.5;
}

.ghost {
  border: 1px solid $gray-2;
  color: $gray-2;
}

.ghost:hover {
  background-color: $gray-1;
}

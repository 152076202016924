@import '~assets/stylesheets/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding-top: 4rem;
  align-items: center;
  //   padding: 9rem 19.5rem;
}

.book-container {
  margin: 2rem 0;
  background-color: white;
  display: flex;
  padding: 3rem;
  border-radius: 10px;
  align-items: center;
}

.title {
  font-size: 3rem;
  font-weight: bold;
}

.name {
  font-size: 2.5rem;
  font-weight: bold;
}

.status {
  margin-right: 5rem;
}

.progress-bar {
  height: 1rem;
  width: 30rem;
  border-radius: 20px;
  background-color: $gray-1;
  margin-right: 6rem;
}

.no-books-msg {
  font-size: 3rem;
  text-align: center;
  padding-top: 8rem;
}

@import '~assets/stylesheets/variables.scss';

.radio-option-container {
  margin: 0.2rem 2rem;
  font-size: 1.8rem;
}

.radio-label {
  display: inline-block;
  color: $gray-3;
}

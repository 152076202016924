@import '~assets/stylesheets/variables.scss';

.file-container {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  border: 1px solid black;
}

.file-input {
  display: none;
}

.delete-button-container {
  // padding: 1rem 2rem;
  cursor: pointer;
  color: white;
  background-color: rgb(233, 29, 29);
  font-size: 1.8rem;
  align-self: flex-start;
  border-radius: 50%;
  position: absolute;
  top: 0.8rem;
  left: 0.8rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 2.5rem;
  }
}

.addtional-info {
  background-color: $very-dark-green;
  font-size: 1.8rem;
  align-self: flex-start;
  border-radius: 15px;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem;
  justify-content: center;
  color: white;
}

.file-preview {
  z-index: 1;
}

.file-input-container {
  padding: 1.5rem 2rem;
  cursor: pointer;
  color: white;
  background-color: $very-dark-green;
  font-size: 1.8rem;
  align-self: flex-start;
  border-radius: 15px;
  display: inline-block;
}

.file-name-label {
  font-size: 1.7rem;
  display: inline-block;
  margin-left: 1rem;
  position: absolute;
  bottom: 0;
  background: rgb(136, 136, 136);
  background: linear-gradient(
    180deg,
    rgba(210, 203, 203, 0.5200674019607843) 91%,
    rgba(9, 9, 121, 0) 100%
  );

  width: 220px;
  height: 40px;
  padding-right: 1rem;
}

.container {
  display: flex;
  align-items: center;
  //   margin-left: 0;
}

.profile-picture {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin: 0 1.5rem 0 0;
}

.name-container {
  font-size: 1.7rem;
  font-weight: bold;
}

.logout-button {
  margin-left: 2rem;
  font-size: 1.4rem;
  text-align: center;
  cursor: pointer;
}

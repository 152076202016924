@import '~assets/stylesheets/variables.scss';

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.flex-column {
  flex-direction: column;
}

.loader {
  border: 1rem solid white;
  border-radius: 50%;
  border-top: 1rem solid $very-dark-green;
  width: 8rem;
  height: 8rem;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hidden {
  display: none;
}

.label {
  font-size: 2.2rem;
  margin-top: 1.5rem;
  color: $dark-green;
}

@import '~assets/stylesheets/variables.scss';

.container {
  display: flex;
  font-size: 2rem;
  .option-container {
    border-color: $very-dark-green;
    border-width: 1px;
    border-style: solid;
    padding: 1rem 3rem;
    border-left-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    flex: 1;
    cursor: pointer;
    background-color: white;

    .option-label {
      font-weight: bold;
    }

    &:last-of-type {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      border-left-width: 1px;
    }

    &:first-of-type {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
  .selected {
    border-color: $very-dark-green;
    background-color: $very-dark-green;
    color: white;
  }

  .inline {
    flex-direction: row;
    .info-icon {
      margin-right: 0.5rem;
    }
  }

  .option-description {
    font-size: 1.3rem;
    color: $gray-2;
  }
}

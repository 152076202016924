@import '~assets/stylesheets/variables.scss';

.container {
  direction: rtl;
  padding: 5rem 20rem;
  font-size: 1.8rem;
  //   width: 60rem;
  display: flex;
  align-items: flex-start;
  .content {
    width: 90rem;
  }
}

.title {
  font-size: 2.2rem;
  font-weight: bold;
}

.paragraph {
  margin: 2rem 0;
}
//---------------------
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10rem;
  direction: rtl;
}

.title {
  font-size: 4rem;
  color: $dark-green;
  margin-bottom: 1rem;
}

.label {
  font-size: 2.5rem;
}

.mail {
  color: $gray-3;
  font-size: 2.5rem;
}

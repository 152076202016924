@import '~assets/stylesheets/variables.scss';

.container {
  direction: rtl;
  display: flex;
  flex: 1;
  justify-content: space-between;
  // flex-direction: column;
  // align-items: center;
  .right-panel,
  .left-panel {
    display: flex;
    align-items: center;
    padding: 0 4rem;
  }
}

.content {
  padding: 2.4rem 0;
  animation-name: fadein;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  z-index: 2;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.title {
  font-size: 2.5rem;
  direction: rtl;
  color: $dark-green;
}

.form-container {
  margin-top: 2rem;
  display: flex;
}

.fields-group {
  display: flex;
  width: 100%;
  margin-left: 5rem;
}

.field-container {
  display: flex;
  direction: rtl;
  flex-direction: column;
  margin: 10px;
  input[type='text'] {
    width: 20rem;
    height: 3rem;
    border-radius: 5px;
    border: 1px solid black;
  }
}

@import '~assets/stylesheets/variables.scss';

.field-container {
  display: flex;
  direction: rtl;
  flex-direction: column;
  margin: 1rem 4rem;
}

.field-label {
  direction: rtl;
  font-size: 1.9rem;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  color: $gray-3;
}

.warning-msg {
  color: red;
  font-size: 1.2rem;
}

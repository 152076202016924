@import '~assets/stylesheets/variables.scss';

.container {
  direction: rtl;
  display: flex;
  flex: 1;
  justify-content: space-between;
  // flex-direction: column;
  // align-items: center;
  .right-panel,
  .left-panel {
    display: flex;
    align-items: center;
    padding: 0 4rem;
  }
}

.content {
  padding: 2.4rem 0;
  animation-name: fadein;
  animation-duration: 0.5s;
  width: 75%;
  z-index: 2;
  overflow: scroll;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.title {
  font-size: 2.5rem;
  color: $very-dark-green;
}

.publishers-container {
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  justify-content: center;
}

@import '~assets/stylesheets/variables.scss';

.container {
  direction: rtl;
  display: flex;
  flex: 1;
  justify-content: space-between;
  // flex-direction: column;
  // align-items: center;
  .right-panel,
  .left-panel {
    padding: 0 4rem;
    display: flex;
    align-items: center;
  }
}

.content {
  padding: 2.4rem 0;
  animation-name: fadein;
  animation-duration: 0.5s;
  // z-index: 2;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.title {
  font-size: 2.5rem;
  direction: rtl;
}

.form-container {
  margin-top: 20px;
  display: flex;
}

.fields-group {
  display: flex;
}

.num-of-pages {
  font-size: 2rem;
  font-weight: bold;
}

.description {
  font-size: 1.5rem;
}

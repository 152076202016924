@import '~assets/stylesheets/variables.scss';

.input {
  width: 25rem;
  font-size: 1.8rem;
  border-radius: 5px;
  border: 1px solid $gray-1;
  padding: 1.6rem 1rem;
  color: $gray-3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.027);
}

.input:focus {
  outline: none !important;
  border: 1px solid $dark-green;
}

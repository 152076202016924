@import '~assets/stylesheets/variables.scss';

.step-number {
  border: 1px solid $gray-2;
  width: 3.4rem;
  height: 3.4rem;
  text-align: center;
  color: $gray-2;
  border-radius: 50%;
  margin: 2rem 2rem 2rem 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.5s ease-in 0.5s, border-color 0.5s ease-in 0.5s,
    color 0.5s ease-in 0.5s;

  // transition: border-color 0.5s linear 1s;
  line-height: 2.5;
}

.label {
  margin-left: auto;
  line-height: 130%;
  font-size: 1.6rem;
  color: $gray-2;
  transition: color 0.5s ease-in 0.5s, font-weight 0.5s ease-in 0.5s;
}

.SELECTED,
.PASSED {
  // animation: opacity 0.5s ease-in-out;
  // animation-fill-mode: both;
  // @keyframes opacity {
  //   0% {
  //     opacity: 0;
  //   }
  //   100% {
  //     opacity: 100%;
  //   }
  // }

  .label {
    font-weight: bold;
    color: $dark-green;
  }
  .step-number {
    border-color: $dark-green;
    color: #ffffff;
    background-color: $dark-green;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 300;
  transition: background-color 0.5s;

  &.vertical-steps {
    flex-direction: column-reverse;
    margin: 0 2rem;

    .step-number {
      margin: 0;
    }

    .label {
      margin-left: 0;
    }
  }
}

.container:last-of-type {
  margin-bottom: auto;
}

.container:hover {
  // background-color: #eef0f2;
}
